<template>
  <div>
    <div class="modal_header">
      <el-row >
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t('message.new_room') }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button @click="save()" :loading="loadingButton" :disabled="loadingButton ? true : false" type="success">{{ $t('message.new_room') }}</el-button>
              <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form ref="form" :model="form" :rules="rules" class="aticler_m" >
      <el-row :gutter="24">

        <el-col :sm="12" class="mt14">
          <div class="grid-content">
            <el-form-item :label="columns.name.title" prop="name">
              <el-input v-model="form.name" :placeholder="columns.name.title"></el-input>
            </el-form-item>
          </div>
        </el-col>

        <el-col :sm="12" class="mt14">
          <div class="grid-content">
            <el-form-item :label="columns.room_number.title" prop="room_number">
              <el-input v-model="form.room_number" :placeholder="columns.room_number.title"></el-input>
            </el-form-item>
          </div>
        </el-col>

      </el-row>
    </el-form>
  </div>
</template>

<script>

import { mapGetters, mapActions} from "vuex";
import form from '@/utils/mixins/form';
export default {
  mixins:[form],
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      roles: 'rooms/list',
      rules: 'rooms/rules',
      model: 'rooms/model',
      columns: 'rooms/columns'
    })
  },
  async created() {},
  methods: {
    ...mapActions({
      roomSave: 'rooms/store',
    }),
    save(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.roomSave(this.form)
            .then(res => {
              this.loadingButton = false;
              this.$alert(res.message);
              this.parent().listChanged()
              this.resetForm('form');
            }).catch(err => {
              this.loadingButton = false;
              this.$notify({
                title: 'Ошибка',
                type: "error",
                offset: 130,
                message: err.error.message
              });
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit('c-close',{drawer: 'drawerCreate'});
    }
  },
  components:{
  }
}

</script> 